import * as React from 'react'
import { StyleSheet } from 'react-native'
import View from '../View'

export interface ModalFooterProps {
  height?: number
  children: JSX.Element[] | JSX.Element
}

export const ModalFooter: React.FC<ModalFooterProps> = ({ height = 50, children }) => {
  return <View style={[styles.container, { height }]}>{children}</View>
}

ModalFooter.displayName = 'Modal.Footer'

const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: 50,
    flexDirection: 'row',
    alignItems: 'flex-end',
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  },
})
