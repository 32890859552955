/* eslint-disable @typescript-eslint/ban-ts-comment */

import * as React from 'react'
import { Pressable, StyleSheet } from 'react-native'
import Text from '../Text'
import { COLOR, FONT } from '@rocket-mono/libs'

interface Props {
  buttonText: string
  subButton?: boolean
  disabled?: boolean
  onPress?: () => void
}

const ButtonWideFooter = ({ buttonText, subButton, disabled, onPress }: Props) => {
  return (
    <Pressable
      onPress={() => !disabled && onPress?.()}
      style={[styles.container, disabled && styles.disabledContainer, subButton && styles.subButtonContainer]}
    >
      <Text style={[styles.textStyle, disabled && styles.textDisabledStyle, subButton && styles.subButtonStyle]}>
        {buttonText}
      </Text>
    </Pressable>
  )
}

export default ButtonWideFooter

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: 48,
    borderRadius: 8,
    backgroundColor: COLOR.primary.blue500,
  },
  disabledContainer: {
    backgroundColor: COLOR.gray.g150,
    cursor: 'auto',
  },
  subButtonContainer: {
    backgroundColor: COLOR.mono.white,
    borderWidth: 1,
    borderColor: COLOR.gray.g300,
  },
  textStyle: {
    ...FONT.txtMdBold,
    color: COLOR.mono.white,
  },
  textDisabledStyle: {
    color: COLOR.gray.g400,
  },
  subButtonStyle: {
    ...FONT.txtMd,
    color: COLOR.gray.g700,
  },
})
