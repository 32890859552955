import { COLOR, FONT } from '@rocket-mono/libs'
import { FAIcon } from '@rui/icons'
import * as React from 'react'
import { Pressable, StyleSheet, Text, View } from 'react-native'
import type { SelectItemType } from '../ItemGroup'

interface Props {
  item: SelectItemType
  mode?: 'LIGHT' | 'DARK'
}
const SelectItem = ({ item, mode }: Props) => {
  const [isHover, setIsHover] = React.useState(false)

  return (
    <Pressable
      onPress={item.onPress}
      onHoverIn={() => setIsHover(true)}
      onHoverOut={() => setIsHover(false)}
      style={[
        styles.container,
        mode === 'LIGHT' && styles.lightContainer,
        isHover && { backgroundColor: COLOR.darkmode.warm400 },
        mode === 'LIGHT' && isHover && { backgroundColor: COLOR.gray.g050 },
        item.type === 'INFO' && { backgroundColor: COLOR.gray.g050 },
      ]}
    >
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <View style={{ flexDirection: 'row', alignItems: 'center', flex: 1 }}>
          {item.beforeIconName && (
            <FAIcon
              iconName={item.beforeIconName}
              size={item.beforeIconSize ? item.beforeIconSize : 'xs'}
              color={item.beforeIconColor ? item.beforeIconColor : 'darkmode.cool300'}
            />
          )}
          <Text
            style={[
              styles.labelText,
              { fontSize: item.labelSize },
              isHover && { color: COLOR.mono.white },
              mode === 'LIGHT' && { color: COLOR.gray.g800 },
              item.beforeIconName && { marginLeft: 4 },
              item.selected && { color: COLOR.primary.blue500 },
            ]}
          >
            {item.label}
          </Text>
        </View>
        <View>
          {item.type === 'COMMON' && item.afterIconName && (
            <FAIcon
              iconName={item.afterIconName}
              size={item.afterIconSize ? item.afterIconSize : 'xs'}
              color={item.selected ? 'primary.blue500' : 'transparent.base'}
            />
          )}
          {item.type === 'SWITCH' && <></>}
        </View>
      </View>
      {item.aboutLabel && <Text style={styles.aboutlabelText}>{item.aboutLabel}</Text>}
    </Pressable>
  )
}

export default SelectItem

const styles = StyleSheet.create({
  container: {
    paddingVertical: 8,
    paddingHorizontal: 12,
    backgroundColor: COLOR.darkmode.warm600,
  },
  lightContainer: {
    backgroundColor: COLOR.mono.white,
  },
  labelText: {
    ...FONT.txtSm,
    color: COLOR.darkmode.cool300,
  },
  aboutlabelText: {
    ...FONT.txt2Xs,
    color: COLOR.gray.g450,
    marginTop: 4,
  },
})
