import * as React from 'react'

import { Modal, StyleSheet, ViewStyle, useWindowDimensions } from 'react-native'
import View from '../View'
import ClickOutside from '../ClickOutside'

export interface ModalProps {
  containerStyle?: ViewStyle
  modalType?: 'full' | 'center' | 'right'
  animationType?: 'slide' | 'none' | 'fade'
  borderType?: 'none' | 'round'
  visible: boolean
  onDismiss?: () => void
  children: JSX.Element[] | JSX.Element
}

export const ModalBase: React.FC<ModalProps> = ({
  containerStyle,
  modalType = 'center',
  animationType = 'slide',
  borderType = 'round',
  visible,
  children,
  onDismiss,
}) => {
  const { width } = useWindowDimensions()

  return (
    <Modal transparent animationType={animationType} visible={visible}>
      <View
        style={[
          styles.containerView,
          modalType === 'full' && { width: '100%' },
          modalType === 'right' && { alignItems: 'flex-end' },
          modalType === 'center' && { alignItems: 'center' },
        ]}
      >
        <View
          style={[
            styles.modalView,
            modalType === 'center' && { alignItems: 'center' },
            borderType === 'round' && {
              borderRadius: 8,
            },
            560 > width && { width: '95%', minWidth: '95%' },
            containerStyle,
          ]}
        >
          <ClickOutside onClickOutside={() => onDismiss && onDismiss()}>{children}</ClickOutside>
        </View>
      </View>
    </Modal>
  )
}

ModalBase.displayName = 'Modal.Base'

const styles = StyleSheet.create({
  containerView: {
    flex: 1,
    justifyContent: 'center',
  },
  modalView: {
    minWidth: 300,
    // maxHeight: 300,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
})
