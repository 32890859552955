/* eslint-disable @typescript-eslint/ban-ts-comment */
import { COLOR, IColors, getRealColor } from '@rocket-mono/libs'
import * as React from 'react'
import {
  Pressable,
  PressableProps,
  StyleSheet,
  StyleProp,
  ViewStyle,
  PressableStateCallbackType,
  GestureResponderEvent,
  Platform,
} from 'react-native'
import View from '../View'
import Text from '../Text'

type Size = 'tiny' | 'small' | 'medium' | 'large' | 'xlarge'

interface Props extends Omit<PressableProps, 'style'> {
  color?: IColors
  backgroundColor?: IColors
  borderColor?: IColors
  borderStyle?: ViewStyle['borderStyle']
  rounded?: boolean
  size?: Size
  wide?: boolean
  style?: StyleProp<ViewStyle>
  hoverStyle?: StyleProp<ViewStyle>
  pressedStyle?: StyleProp<ViewStyle>
  disabledStyle?: StyleProp<ViewStyle>
}

const Button = ({
  size = 'medium',
  color = 'mono.white',
  backgroundColor = 'main.blue',
  borderColor = 'transparent',
  borderStyle = 'solid',
  rounded = false,
  wide = false,
  onPressIn,
  onPressOut,
  style,
  hoverStyle,
  pressedStyle,
  disabledStyle,
  disabled,
  children,
  ...props
}: Props) => {
  const _onPressIn = React.useCallback(
    (event: GestureResponderEvent) => {
      onPressIn && onPressIn(event)
    },
    [onPressIn],
  )

  const _onPressOut = React.useCallback(
    (event: GestureResponderEvent) => {
      onPressOut && onPressOut(event)
    },
    [onPressOut],
  )

  const onStyle = React.useCallback(
    // @ts-ignore hovered only used in web
    ({ hovered, pressed }: PressableStateCallbackType) => {
      return [
        styles.pressable,
        {
          ...getDimensions(size, wide),
          borderRadius: rounded
            ? geRoundedBoarderRadius(getDimensions(size, wide))
            : getBorderRadius(getDimensions(size, wide)),
          backgroundColor: getRealColor(backgroundColor),
          borderColor: getRealColor(borderColor),
          borderStyle,
        },
        style,
        !disabled && hovered && (hoverStyle ?? styles.hover),
        !disabled && pressed && (pressedStyle ?? styles.pressed),
        disabled && (disabledStyle ?? styles.disabled),
      ] as ViewStyle[]
    },
    [
      size,
      wide,
      rounded,
      backgroundColor,
      borderColor,
      borderStyle,
      style,
      hoverStyle,
      pressedStyle,
      disabledStyle,
      disabled,
    ],
  )

  return (
    <View
      // @ts-ignore web only
      style={[disabled && Platform.select({ web: { cursor: 'not-allowed' } })]}
    >
      <Pressable style={onStyle} onPressIn={_onPressIn} onPressOut={_onPressOut} disabled={disabled} {...props}>
        {printContent({ children, color })}
      </Pressable>
    </View>
  )
}

export default Button

interface Dimensions {
  minWidth: number | string
  height: number | string
}

const printContent = ({
  children,
  color,
}: {
  children?: PressableProps['children']
  color: IColors
}): PressableProps['children'] => {
  if (typeof children === 'string') {
    return (
      <Text fontName="textRegular" fontColor={color} selectable={false}>
        {children}
      </Text>
    )
  }

  return children
}

const getDimensions = (size: Size, wide: boolean): Dimensions => {
  const CONTAINER_SIZE = {
    tiny: {
      height: 26,
      minWidth: 26,
    },
    small: {
      height: 28,
      minWidth: 40,
    },
    medium: {
      height: 33,
      minWidth: 60,
    },
    large: {
      height: 36,
      minWidth: 100,
    },
    xlarge: {
      height: 51,
      minWidth: 224,
    },
  } as const

  if (wide) {
    return {
      height: CONTAINER_SIZE[size].height,
      minWidth: '100%',
    }
  }

  return CONTAINER_SIZE[size]
}

const geRoundedBoarderRadius = ({ minWidth, height }: Dimensions) => {
  if (typeof minWidth === 'string' || typeof height === 'string') {
    return 9999
  }
  return Math.max(minWidth, height)
}

const getBorderRadius = ({ minWidth, height }: Dimensions) => {
  if (typeof minWidth === 'string' || typeof height === 'string') {
    return 5
  }
  return Math.min(minWidth, height) * 0.14
}

const styles = StyleSheet.create({
  pressable: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'flex-start',
    borderWidth: 1,
  },
  hover: {
    opacity: 0.8,
  },
  pressed: {
    opacity: 0.5,
  },
  disabled: {
    backgroundColor: COLOR.mono.lightGray,
  },
})
