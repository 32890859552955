import * as React from 'react'
import { Platform, StyleSheet } from 'react-native'
import View from '../View'
import Text from '../Text'
import { COLOR } from '@rocket-mono/libs'
import ButtonIcon from '../ButtonIcon'
// import { ButtonIcon } from '@rocket/components'

export interface ModalHeaderProps {
  children: string | JSX.Element | JSX.Element[]
  textAlign: 'left' | 'center'
  height?: number
  onPressBack?: () => void
  closeIcon?: JSX.Element
  onPressClose?: () => void
}

export const ModalHeader: React.FC<ModalHeaderProps> = ({
  children,
  textAlign,
  height = 56,
  // onPressBack,
  closeIcon,
  onPressClose,
}) => {
  return (
    <View style={[styles.headerView, { height }]}>
      {typeof children === 'string' ? (
        <Text style={{ ...styles.text, textAlign }} fontName="subTitleBold" fontColor="gray.g900">
          {children}
        </Text>
      ) : (
        <>{children}</>
      )}
      {/* {!!onPressBack && (
        // <ButtonIcon
        //   style={styles.backIcon}
        //   iconName="angle-left"
        //   iconSize={20}
        //   containerSize={52}
        //   onPress={onPressBack}
        // />
      )} */}
      {closeIcon && (
        <ButtonIcon
          style={styles.closeIcon}
          icon={closeIcon}
          // iconName="close"
          // iconSize={20}
          // iconColor="gray.g700"
          containerSize={52}
          onPress={onPressClose}
        />
      )}
    </View>
  )
}

ModalHeader.displayName = 'Modal.Header'

const styles = StyleSheet.create({
  headerView: {
    width: '100%',
    paddingHorizontal: 24,
    flexDirection: 'row',
    alignItems: 'center',
    borderBottomWidth: 1,
    borderBottomColor: COLOR.gray.g150,
    ...Platform.select({
      web: {
        outline: 'none',
      },
    }),
  },
  text: {
    flex: 1,
  },
  backIcon: {
    position: 'absolute',
    left: 0,
  },
  closeIcon: {
    position: 'absolute',
    right: 0,
  },
})
