import React from 'react'

import { createBrowserRouter, Outlet } from 'react-router-dom'
// import { LetterDuplicatedErrorPage, LetterExpiredErrorPage } from './pages/Error'
// import { LetterAgreementPage, LetterSubmittedPage } from './pages/Letter'

import RootProvider from './providers'
// import IntroducePage from './pages/Introduce'
// import SubmissionPage from './pages/Submission'
// import DocumentsPage from './pages/Documents'

const LetterDuplicatedErrorPage = React.lazy(() => import('./pages/Error/duplicated'))
const LetterExpiredErrorPage = React.lazy(() => import('./pages/Error/expired'))

const LetterAgreementPage = React.lazy(() => import('./pages/Letter/agreement'))
const LetterSubmittedPage = React.lazy(() => import('./pages/Letter/submitted'))
const SignatureGuidePage = React.lazy(() => import('./pages/Letter/signature-guide'))

const IntroducePage = React.lazy(() => import('./pages/Introduce'))
const SubmissionPage = React.lazy(() => import('./pages/Submission'))
const DocumentsPage = React.lazy(() => import('./pages/Documents'))

const router = createBrowserRouter([
  {
    path: '',
    element: (
      <RootProvider>
        <Outlet />
      </RootProvider>
    ),
    children: [
      {
        path: '',
        element: <LetterExpiredErrorPage />,
      },
      {
        path: '/letters/:letterId',
        element: <LetterAgreementPage />,
      },
      {
        path: '/submission/:letterId',
        element: <SubmissionPage />,
      },
      {
        path: '/expired',
        element: <LetterExpiredErrorPage />,
      },
      {
        path: '/duplicated',
        element: <LetterDuplicatedErrorPage />,
      },
      {
        path: '/submitted',
        element: <LetterSubmittedPage />,
      },
      {
        path: '/signature-guide/:campaignLetterId',
        element: <SignatureGuidePage />,
      },
    ],
  },
  {
    path: 'introduce',
    element: <IntroducePage />,
  },
  {
    path: 'documents',
    element: <DocumentsPage />,
  },
])

export default router
