import * as React from 'react'
import { StyleSheet, Animated, DimensionValue } from 'react-native'
import { COLOR, IColors, getRealColor } from '@rocket-mono/libs'

interface Props {
  width: DimensionValue
  height: number
  color?: IColors
}


export const SkeletonCircle = ({ width = 40, height = 40, color }: Props) => {
  const [opacityAnim] = React.useState(new Animated.Value(1))
  const [animatedLoopCheck, setAnimatedLoopCheck] = React.useState(1)

  React.useEffect(() => {
    if(animatedLoopCheck === 0){
      Animated.timing(opacityAnim, {
        toValue: 1,
        duration: 2000,
        useNativeDriver: false,
      }).start()
      setTimeout(() => {
        setAnimatedLoopCheck(1)
      }, 2500);
      
    } else {
      Animated.timing(opacityAnim, {
        toValue: 0.5,
        duration: 2000,
        useNativeDriver: false,
      }).start()
      setTimeout(() => {
        setAnimatedLoopCheck(0)
      }, 2500);
    }
  }, [animatedLoopCheck])

  return (
    <Animated.View 
      style={[
        styles.skeletonCircle, 
        { width, height, opacity: opacityAnim }, 
        color && {backgroundColor: getRealColor(color)}
      ]}
    ></Animated.View>
  )
}

export const SkeletonBar = ({ width=40, height=40, color }: Props) => {
  const [opacityAnim] = React.useState(new Animated.Value(1))
  const [animatedLoopCheck, setAnimatedLoopCheck] = React.useState(1)

  React.useEffect(() => {
    if(animatedLoopCheck === 0){
      Animated.timing(opacityAnim, {
        toValue: 1,
        duration: 2000,
        useNativeDriver: false,
      }).start()
      setTimeout(() => {
        setAnimatedLoopCheck(1)
      }, 2500);
      
    } else {
      Animated.timing(opacityAnim, {
        toValue: 0.5,
        duration: 2000,
        useNativeDriver: false,
      }).start()
      setTimeout(() => {
        setAnimatedLoopCheck(0)
      }, 2500);
    }
  }, [animatedLoopCheck])

  return (
    <Animated.View style={[
      styles.skeletonBox, 
      { width, height, opacity: opacityAnim }, 
      color && {backgroundColor: getRealColor(color)}
    ]}
    ></Animated.View>
  )
}

export const SkeletonSquare = ({ width=40, height=40, color }: Props) => {
  const [opacityAnim] = React.useState(new Animated.Value(1))
  const [animatedLoopCheck, setAnimatedLoopCheck] = React.useState(1)

  React.useEffect(() => {
    if(animatedLoopCheck === 0){
      Animated.timing(opacityAnim, {
        toValue: 1,
        duration: 2000,
        useNativeDriver: false,
      }).start()
      setTimeout(() => {
        setAnimatedLoopCheck(1)
      }, 2500);
      
    } else {
      Animated.timing(opacityAnim, {
        toValue: 0.5,
        duration: 2000,
        useNativeDriver: false,
      }).start()
      setTimeout(() => {
        setAnimatedLoopCheck(0)
      }, 2500);
    }
  }, [animatedLoopCheck])

  return (
  <Animated.View style={[
    styles.skeletonSquare, 
    { width, height, opacity: opacityAnim }, 
    color && {backgroundColor: getRealColor(color)}
  ]}
  ></Animated.View>)
}

const styles = StyleSheet.create({
  skeletonCircle: {
    borderRadius: 30,
    backgroundColor: COLOR.gray.g150,
  },
  skeletonSquare: {
    borderRadius: 5,
    backgroundColor: COLOR.gray.g200,
  },
  skeletonBox: {
    borderRadius: 2,
    backgroundColor: COLOR.gray.g100,
  },
})
