import * as React from 'react'
import { DimensionValue, ScrollView, StyleSheet, ViewStyle, useWindowDimensions } from 'react-native'
import View from '../View'

export interface ModalBodyProps {
  style?: ViewStyle
  innerStyle?: ViewStyle
  children?: JSX.Element[] | JSX.Element
  isDirect?: boolean
  maxHeight?: DimensionValue
  contentContainerStyle?: ViewStyle
}

export const ModalBody: React.FC<ModalBodyProps> = ({ style, innerStyle, children, isDirect = false, maxHeight, contentContainerStyle }) => {
  const { height: windowHeight } = useWindowDimensions()

  return isDirect ? (
    <View style={[styles.contentsView, style]}>{children}</View>
  ) : (
    <ScrollView style={[styles.contentsView, style]} contentContainerStyle={contentContainerStyle}>
      <View style={[maxHeight? {maxHeight} : { maxHeight: windowHeight - 200 }, innerStyle]}>{children}</View>
    </ScrollView>
  )
}

ModalBody.displayName = 'Modal.Body'

const styles = StyleSheet.create({
  contentsView: {
    flex: 1,
    padding: 24,
  },
})
